@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body,
#root {
  height: 100%;
  font-family: "Inter", sans-serif;
  margin: auto;
  color: #000;
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.p-component{
  font-family: "Inter", sans-serif;
}
.btn:active {
  border-color: transparent !important;
}
select.form-control {
  background-image: url(../img/select-angle.png);
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 25px;
}
.dropdown-icon-none:after {
  content: none !important;
}
select.no-select-angle {
  background-image: none;
}
.text-light-grey {
  color: #535353 !important;
}
.text-blue {
  color: #1749b9 !important;
}
.border-blue {
  border-color: #1749b9 !important;
}

.fa-light,
.fa-regular {
  vertical-align: middle;
}

.bg-blue {
  background-color: #1749b9 !important;
}
.text-green {
  color: #13bf11 !important;
}
.border-green {
  border-color: #13bf11 !important;
}
.bg-green {
  background-color: #13bf11 !important;
}
.text-red {
  color: #fa0000 !important;
}
.border-red {
  border-color: #fa0000 !important;
}
.bg-red {
  background-color: #fa0000 !important;
}

.input_icon_wrap {
  position: relative;
}
.input_icon {
  position: absolute;
  top: 0;
  left: 10px;
  bottom: 0;
  margin: auto;
  height: fit-content;
  font-size: 14px;
  color: #606060;
}
.input_icon i {
  vertical-align: text-bottom;
}
.mw100 {
  width: 100%;
  max-width: 100px;
}
.mw150 {
  width: 100%;
  max-width: 150px;
}
.mw200 {
  width: 100%;
  max-width: 200px;
}
.mw300 {
  width: 100%;
  max-width: 300px;
}

.btn-blue {
  background-color: #232c69 !important;
}
.btn-gradient {
  background: rgb(245, 247, 249);
  background: linear-gradient(0deg, rgba(245, 247, 249, 1) 0%, rgba(255, 255, 255, 1) 100%);
  border-color: #cfd7df;
}
.btn.btn-gradient:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(245, 247, 249, 1) 100%);
  border: 1px solid #eee;
  opacity: 1;
}
.text-red-2 {
  color: #db0f79;
}
.bg-red-2 {
  background-color: #db0f79;
}
.fw100 {
  font-weight: 100;
}
.fw200 {
  font-weight: 200;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fw700 {
  font-weight: 700;
}
.fw800 {
  font-weight: 800;
}
.fw900 {
  font-weight: 900;
}
ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}

p,
input,
button,
select,
textarea,
span,
a,
div,
.form-select-sm,
table,
.form-group,
.dataTables_length,
.dataTables_filter,
.dataTables_info,
.pagination {
  font-size: 1.4rem;
}

.calender-icon-bg {
  background-image: url(../img/calendar-icon.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
}

.fz14,
.dropdown-toggle {
  font-size: 1.4rem !important;
}
.fz16 {
  font-size: 1.6rem !important;
}
.fz18 {
  font-size: 1.8rem !important;
}
.fz20 {
  font-size: 2rem !important;
}
.fz22 {
  font-size: 2.2rem !important;
}
.fz24 {
  font-size: 2.4rem !important;
}
.fz26 {
  font-size: 2.6rem !important;
}
.fz28 {
  font-size: 2.8rem !important;
}
.fz30 {
  font-size: 3rem !important;
}
.fz32 {
  font-size: 3.2rem !important;
}
.fz34 {
  font-size: 3.4rem !important;
}
.fz36 {
  font-size: 3.6rem !important;
}
.fz38 {
  font-size: 3.8rem !important;
}
.fz40 {
  font-size: 4rem !important;
}
.outline-0 {
  outline: none;
}
.btn-group {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 1.4rem;
}
.modal-backdrop {
  width: 100%;
  height: 100%;
}
.btn-group * {
  border-radius: 4px !important;
}

.bg-sky-blue {
  background-color: #d5f4ff;
}
.bg-light-blue {
  background-color: #d9ecfc;
}
.bg-gray {
  background-color: #f9f9f9;
}
.border-dotted {
  border-style: dashed;
}

.btn {
  font-size: 1.4rem;
}
a {
  text-decoration: none;
}
.dropdown-menu {
  min-width: 175px;
  font-size: 1.4rem;

  background-color: #fff;
}
.dropdown-btn-toggle {
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-autocomplete-wrap {
  position: absolute;
  background: #fff;
  width: 100%;
  border: 1px solid #eee;
  top: 35px;
  left: 0;
  display: none;
  z-index: 3;
}
.custom-autocomplete-box {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #eee;
}
.custom-autocomplete-wrap .ui-autocomplete {
  position: static !important;
  display: block !important;
  width: 100% !important;
  background-color: #fff;
  border: 0;
}

.ui-menu .ui-menu-item-wrapper {
  padding: 6px 10px;
}
.template-default {
  display: flex;
  width: 100%;
  flex: 1;
  overflow: hidden;
  height: 100%;
  font-size: 1.6rem;
}
.form-check .form-check-input {
  font-size: 2rem;
}

.autoCompleteClear {
  position: absolute;
  top: 8px;
  right: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.fixed-header-content {
  display: flex;
  width: 100%;
  flex: 1;
  overflow: hidden;
  height: 100%;
  margin-left: 250px;
  transition: 0.3s;
  flex-direction: column;
}

#template-default-page.menu-closed .fixed-header-content {
  margin-left: 78px;
}
/* page-wrapper-width-aisde */
.page-wrapper-with-aisde {
  flex: 1;
  display: flex;
  overflow: hidden;
}
.page-wrapper-without-aisde {
  flex: 1;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* aside-bar */

.main-nav ul > li:hover a:after,
.main-nav ul > li a.active:after {
  color: #fff;
}
@media (min-width: 1201px) {
  .aside-bar.active * {
    display: none;
  }
}
.aside-bar .aside-header .asidebar-toggle {
  /* position: absolute; */
  display: block;
}
.aside-bar .aside-header .asidebar-toggle * {
  display: block;
}
.aside-bar.active .asidebar-toggle {
  margin-top: 8px;
  background: #eee;
  transform: rotate(180deg);
}
.aside-bar .aside-header .asidebar-toggle:hover {
  background-color: #eee;
}
.aside-bar .aside-body {
  flex: 1;
  overflow: auto;
}
.aside-bar .aside-footer {
  padding: 15px;
}

.aside-body ul li a {
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  display: inline-flex;
}
.aside-body ul li a:hover {
  color: #ee4047;
}

.circle-indicator {
  display: inline-block;
  height: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: #eee;
  margin-right: 8px;
  margin-top: 3px;
}
.green-indictor {
  background-color: #009b02;
}
.blue-indictor {
  background-color: #232c69;
}
.grey-indictor {
  background-color: #909090;
}
.red-indictor {
  background-color: #ef4047;
}
.yellow-indictor {
  background-color: #ffc55f;
}

/* template-default-page */

.transport-admin #menuBar-toggle {
  width: 250px;
  position: fixed;
  background-color: #fff;
  z-index: 3;
  height: 100%;
  transition: 0.3s;
  box-shadow: 1px 1px 4px 0px #e2e2e2;
  display: flex;
  flex-direction: column;
}


.transport-admin  .main-nav ul li a, .transport-admin  .main-nav ul li a i{
  color: #000 !important;
}
.transport-admin  .main-nav ul li a span{
  color: #2c2c2c !important;
}
.transport-admin  .main-nav > ul > li:hover > a{
  background-color: #00000014;
  color: #2d3670;
}
.transport-admin  .main-nav ul > li > a.active span,.transport-admin  .main-nav ul li a.active i {
  color: #009b02 !important;
}


.customer-admin  #menuBar-toggle {
  width: 250px;
  position: fixed;
  background-color: #eff5ef;
  z-index: 3;
  height: 100%;
  transition: 0.3s;
  box-shadow: 1px 1px 4px 0px #e2e2e2;
  display: flex;
  flex-direction: column;
}
.customer-admin  .main-nav ul li a, .customer-admin  .main-nav ul li a i{
  color: #000 !important;
}
.customer-admin  .main-nav ul li a span{
  color: #2c2c2c !important;
}
.customer-admin  .main-nav > ul > li:hover > a{
  background-color: #00000014;
  color: #2d3670;
}
.customer-admin  .main-nav ul > li > a.active span,.customer-admin  .main-nav ul li a.active i {
  color: #009b02 !important;
}

.platform-admin  #menuBar-toggle {
  width: 250px;
  position: fixed;
  background-color: #2c3e50;
  z-index: 3;
  height: 100%;
  transition: 0.3s;
  box-shadow: 1px 1px 4px 0px #e2e2e2;
  display: flex;
  flex-direction: column;
}

.platform-admin  .main-nav ul li a, .platform-admin  .main-nav ul li a i{
  color: #ffff !important;
}
.platform-admin  .main-nav ul li a span{
  color: #fff !important;
}
.platform-admin  .main-nav > ul > li:hover > a{
  background-color: #00000014;
  color: #2d3670;
}
.platform-admin  .main-nav ul > li > a.active span,.platform-admin  .main-nav ul li a.active i {
  color: #959595 !important;
}

.template-default .sm-logo {
  display: none;
}
.template-default .lg-logo {
  display: block;
}
@media (min-width: 1201px) {
  #template-default-page.menu-closed #menuBar-toggle {
    width: 78px;
  }

  .template-default.menu-closed .sm-logo {
    display: block;
    max-width: 46px;
    margin: 4px;
  }
  .template-default.menu-closed .lg-logo {
    display: none;
  }

  #template-default-page.menu-closed .main-nav ul li a span {
    opacity: 0;
    visibility: hidden;
    background: #333333;
    padding: 6px 12px;
    border-radius: 5px;
    color: #fff !important;
    margin-left: -20px;
    position: relative;
  }

  #template-default-page.menu-closed .main-nav ul li a span::before {
    content: "";
    position: absolute;
    border-width: 10px;
    border-style: solid;
    border-color: transparent;
    border-right-color: #333333;
    left: -19px;
  }
  #template-default-page.menu-closed .main-nav ul li a:hover span {
    opacity: 1;
    visibility: visible;
    margin-left: -10px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }

  #template-default-page.menu-closed .main-nav ul li a.hasSubMenu span:before {
    opacity: 0;
  }
  #template-default-page.menu-closed .main-nav ul li a.hasSubMenu:before {
    opacity: 1;
    content: "";
    height: 90%;
    width: 2px;
    background-color: #ce2027;
    z-index: -1;
    left: 0;
    display: inline-block;
    position: absolute;
    transition: 0.3s;
    top: 0;
    bottom: 0;
    margin: auto;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
  }
  #template-default-page.menu-closed .main-nav > ul > li:hover > a.hasSubMenu i {
    color: #fff;
  }
  #template-default-page.menu-closed .main-nav ul li:hover a.hasSubMenu:before {
    width: 100%;
  }
  #template-default-page.menu-closed .main-nav > ul {
    overflow: unset;
  }
  .main-nav > ul {
    overflow: auto;
    white-space: nowrap;
    /* border-top: 1px solid #dad7d7; */
  }

  #template-default-page.menu-closed #menuBar-toggle:hover ul li a.hasSubMenu span:before {
    opacity: 1;
  }
}

/* shadow notification-header */
.notification-header {
  min-width: 390px;
  padding: 0;
}
.notification-header .dropdown-body {
  max-height: 350px;
  overflow: auto;
}
.notification-header .dropdown-body .notify-img {
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: #eee;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2e3772;
}
.notification-header .dropdown-body li {
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.notification-header .dropdown-body li .text-black {
  font-size: 12px;
}
.dropdown-small {
  width: 100%;
  max-width: 200px !important;
}
.dropdown-large {
  width: 100%;
  max-width: 590px !important;
}
.dropdown-x-large {
  width: 100%;
  max-width: 800px !important;
}

/* dashboardTabs */
.dashboardTabs.custom-tabs .nav-link {
  background-color: transparent;
  border: 1px solid #cfd7df;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.dashboardTabs.custom-tabs .nav-link.active {
  border-bottom: 1px solid #cfd7df;
  background: #edeef2;
  color: #000;
}
/* cusom checkbox */

.table-wrapper tr td .dropdown-menu {
  padding: 0;
}
.table-wrapper tr td .btn {
  box-shadow: none;
}
.table-wrapper tr td .dropdown-item {
  padding: 6px 10px;
  border-bottom: 1px solid #eee;
}
.table-wrapper tr td .dropdown-item i {
  margin-right: 10px;
}
.table th {
  border-bottom-color: rgba(222, 226, 230) !important;
}
.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
}

.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #717171;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 5px;
}
.custom-checkbox input:checked + label:before {
  background-color: #029a06;
  border: 1px solid #029a06;
}
.custom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-checkbox input + label {
  line-height: 0;
  display: block;
}

.profile-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: var(--bs-gray-dark);
  text-align: center;
  line-height: 30px;
  font-size: 1.3rem;
  text-transform: uppercase;
  color: #fff;
}

.hamburger {
  width: 24px;
  height: 24px;
  border: 1px solid #ececec;
  border-radius: 50%;
  background: #fff;
  font-size: 1.2rem;
  transform: rotate(180deg);
  color: #757373;
  /* position: absolute;
  right: -11px;
  z-index: 2;
  bottom: -11px; */
}
.body-content-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 20px;
  background: #f8fafc;
}
.body-content-top {
  padding: 20px 0;
}
.sidebar-logo {
  min-height: 53px;
  /* display: flex; */
  padding: 15px 0px;
  align-items: center;
}
.card {
  border-radius: 1rem;
  color: #64748b;
  padding: 5px;
  -webkit-border-radius: 1rem;
  border: 0;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  box-shadow: 0 3px 10px 0 rgb(48 46 56/6%);
}

.wrapper {
  width: 100%;
}
.form-control {
  padding: 7px 5px;
  height: inherit;
  font-size: 1.4rem;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
}
.dropdown-toggle:after {
  content: "\f107";
  font-family: "Font Awesome 6 Pro";
  border: 0;
  line-height: 1;
  vertical-align: baseline;
}

.form-control:hover {
  border-color: #94a3b8;
}
.form-control:focus {
  border-color: #94a3b8;
  box-shadow: none;
}

.table tbody tr:last-child td {
  border-bottom: 0;
}
.card label {
  font-size: 14px;
  font-weight: 500;
}
/* top-strip */
.top-strip {
  padding: 10px 0;
  border-bottom: 1px solid #dad7d7;
}
.header-right-links > li {
  margin-left: 10px;
}
.header-right-links > li > a {
  justify-content: center;
  font-size: 2.2rem;
  color: #000;
  line-height: normal;
  display: flex;
  align-items: center;
}
.header-right-links > li > a:hover {
  color: #cf0808 !important;
}

.header-right-links .badge {
  background-color: #ef4047;
  font-size: 10px;
  width: 15px;
  height: 15px;
  padding: 0;
  font-weight: 600;
  line-height: 15px;
  border-radius: 50%;
  top: 6px !important;
}


.main-nav ul li {
  position: relative;
  padding: 4px 10px;
}
.main-nav ul li a {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  color: #000;
  perspective: 500px;
  white-space: nowrap;
  display: block;
  position: relative;
  border-radius: 6px;
}
.main-nav ul li a i {
  font-size: 2rem;
  width: 55px;
  text-align: center;
  color: #fff;
  vertical-align: text-bottom;
}


.btn {
  border-radius: 0.5rem;
  letter-spacing: 0.025em;
  padding: 0.5rem 1.25rem;
}

.text-slate-800 {
  color: rgb(0 155 1);
}

.main-nav > ul > li:hover > a {
  background-color: #dedede42;
  color: #2d3670;
}
.main-nav ul > li > a.active span {
  color: #959595 !important;
}
.main-nav ul li a.active i {
  color: #959595 !important;
}

.main-nav ul li:hover > a i {
  color: #484c66;
}
.main-nav ul li a i {
  font-size: 2rem;
  width: 55px;
  text-align: center;
  vertical-align: text-bottom;
}

.main-nav ul li a span {
  opacity: 1;
  position: static;
  background-color: transparent;
  margin: 0;
  padding: 0;
  margin-left: -10px;
  height: auto;
  border-radius: unset;
  font-weight: inherit;
  visibility: visible;
  color: #fff !important;
  font-size: 15px;
  font-weight: 500;
  vertical-align: bottom;
}

aside.main-nav .hasSubMenu span::before {
  content: "\f107";
  font-family: "Font Awesome 6 Pro";
  color: #7c7c7c;
  font-size: 12px;
  margin-right: 15px;
  position: absolute;
  right: 0;
  top: 9px;
}

#template-default-page .hamburger {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}
#template-default-page.menu-closed .hamburger {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

/* main-nav-mega-menu */

.main-nav-mega-menu {
  display: none;
  background: #ffff;
  background: #eee;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid #eee;
}
#template-default-page.menu-closed .main-nav-mega-menu {
  display: block;
  position: absolute;
  left: 100%;
  top: 10px;
  opacity: 0;
  visibility: hidden;
  min-width: 210px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
#template-default-page.menu-closed .main-nav ul li:hover > .main-nav-mega-menu {
  opacity: 1;
  visibility: visible;
  top: 0px;
}
.main-nav ul li .main-nav-mega-menu a {
  font-size: 13px;
  text-transform: capitalize;
  padding: 3px 10px;
  white-space: normal;
}
.main-nav ul li .main-nav-mega-menu a:hover {
  color: #ef4047;
}

/* chat-system-wrap */
.chat-system-wrap .chat-left-asidebar {
  min-width: 390px;
  max-width: 390px;
  width: 100%;
  border-right: 1px solid #e2e4e6;
  border-right: 1px solid #e2e4e6;
}
.chat-system-wrap .chat-right-page {
  flex-grow: 1;
}

.user--profile {
  margin-bottom: 0;
  min-width: 40px !important;
  height: 40px;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50% !important;
  min-width: 40px;
}
.user--profile img {
  width: 100%;
  height: 100%;
}
.chatbox-dropdown-left-slide {
  position: static;
}
.chatbox-dropdown-left-slide .dropdown-menu {
  width: 100%;
  height: 100%;
  transform: none !important;
  inset: unset !important;
  margin: 0 !important;
  min-width: 100%;
  right: auto !important;
  left: -100% !important;
  top: 0 !important;
  padding: 0;
  z-index: 1;
  position: absolute !important;
  transition: 0.3s ease;
  display: block !important;
  border-left: 0;
  border-radius: 0 !important;
}
.chatbox-dropdown-left-slide .dropdown-menu.show {
  left: 0 !important;
}
.chat-left-header .btn-group {
  position: static;
}
.chat-user-content {
  position: relative;
}
.chat-user-content h4 {
  color: #393939;
  text-transform: capitalize;
}
.chat-user-content h4 + span {
  white-space: nowrap;
}
.chat-users-list .chat-user {
  padding-left: 25px;
  padding-right: 15px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #e2e4e6;
}
.chat-users-list .chat-user:hover,
.chat-users-list .chat-user.active {
  background-color: #eee;
}

.chat-user-content a {
  color: #000 !important;
}
.chat-user-content .msg-counter {
  position: absolute;
  width: 25px;
  height: 25px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  border-radius: 50%;
  background: #232c69;
  right: 0px;
  bottom: -7px;
  color: #fff;
  line-height: 25px;
}

.chat-left-header .user--profile-wrap,
.chat-right-page-header {
  min-height: 65px;
  background-color: #f0f2f5;
  border-bottom: 1px solid #e2e4e6;
  max-height: 65px;
  height: 65px;
}
.chat-right-page-footer {
  background-color: #f0f2f5;
  min-height: 70px;
  display: flex;
  align-items: center;
  border-top: 1px solid #dee2e6;
}
.support-chat-new .chat-right-page-footer {
  min-height: 110px;
}
.support-chat-new .typing-chat-input {
  height: 80%;
  padding: 10px;
  resize: none;
}
.user-chat-search {
  display: flex;
}
.user-search-input-div .form-control {
  box-shadow: none;
  padding-left: 40px;
  width: 100%;
}

.user-search-input-div {
  width: 0;
  overflow: hidden;
  transition: 0.3s;
  margin-right: 10px;
}
.user-search-input-div.active {
  width: 100%;
}

.chatStatusBtn.active {
  position: relative;
}
.chatStatusBtn.active::after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #232c69;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
}

.toggle-search-btn .hide,
.toggle-search-btn.active .show {
  display: none;
}
.toggle-search-btn.active .hide {
  display: block;
}
.chat-right-page-body {
  background-color: #f7f8fa;
  padding: 40px 60px;
}

.user-chat-box {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.chat-box-left .user-chat-box {
  background-color: #fff;
  position: relative;
}
.chat-box-left .user-chat-box::before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px;
  border-color: transparent white transparent transparent;
  left: -16px;
}
.chat-box-right {
  text-align: right;
}
.chat-box-right .user-chat-box {
  background-color: #e3e6f8;
  max-width: 80%;
  margin-left: auto;
  text-align: left;
  padding-left: 20px;
}

.typing-chat-input {
  height: 55px;
  margin-left: 10px;
  font-size: 16px;
  color: #555555;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600 !important;
  box-shadow: none;
  outline: none;
}

/* uplpoad  */

.uploadingarea {
  border: #c7c7c7 dashed 2px;
}
.uploadingarea i {
  font-size: 36px;
  color: #47bdff;
}
.uploadingrow a {
  color: #0962be;
}
.uploadingarea h3 {
  font-size: 15px;
  font-weight: 600;
}

.upbtn .btn {
  padding: 9px 45px;
  border-radius: 0px;
}
#myModal .modal-header {
  font-weight: 600 !important;
}

.uploadingdocswrap .table thead th {
  font-weight: 600;
}
.uploadingdocswrap table tbody tr td {
  vertical-align: middle;
}

/* main footer */
.main-footer {
  background-color: #fff;
  border-top: 1px solid #eee;
  position: relative;
  z-index: 2;
}

.replyBtnBlue {
  background-color: #d9edf7 !important;
  color: #000 !important;
}
.replyBtnBlue.accordion-button::after {
  margin-left: 50px;
}
/* datatable */
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  justify-content: flex-start;
}
.table-wrapper table a:hover {
  opacity: 0.6;
}
.table-wrapper table tbody {
  border-top-color: #eee !important ;
}
.table-wrapper table th {
  border-color: #dee2e6 !important;
  font-size: 1.3rem !important;
  padding: 1rem;
}
.table-wrapper table {
  margin-top: 0 !important;
}
.table-wrapper table td {
  font-size: 1.3rem !important;
  font-weight: 500;
  padding: 1rem;
}

.pagination {
  gap: 7px;
}
.dataTables_paginate .page-item:first-child .page-link,
.dataTables_paginate .page-item:last-child .page-link {
  border-radius: 4px;
}
.dataTables_paginate .page-link {
  padding: 3px 9px;
  font-size: 1.2rem;
  color: #717171;
}
.dataTables_paginate .page-item.active .page-link {
  background-color: #13bf11;
  border-color: #13bf11;
}

.container-track {
  color: #2e3772;
  font-size: 1.4rem;
  font-weight: 500;
  opacity: 0;
}
.table-wrapper table tr:hover .container-track {
  opacity: 1;
}
.ck-editor__editable {
  min-height: 150px;
  max-height: 150px;
}
.table-wrapper table td,
.table-wrapper table th {
  font-size: 1.4rem;
}
.dataTables_length {
  font-size: 1.4rem;
}
.table-wrapper table th,
.table-wrapper table td {
  vertical-align: middle;
}

/* custom-dessign-dropdow */
.custom-dessign-dropdown .dropdown-btn {
  font-size: 1.5rem;
  width: 200px;
  white-space: nowrap;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-dessign-dropdown .dropdown-menu {
  width: 100%;
  max-width: 245px;
  border-color: #cfd7df;
  position: fixed !important;
}

.max-height-300 {
  max-height: 300px;
  overflow: auto;
}
.custom-dessign-dropdown .dropdown-menu li a {
  font-size: 1.4rem;
  color: #292728;
}

.ui-menu-item-wrapper {
  display: block;
}
.dynamicTextArea {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  background: #ffff;
  z-index: 1;
}

textarea.resize-none {
  line-height: 25px;
  overflow: hidden;
  resize: none;
}
.progress-departed-arrived {
  list-style-type: none;
  display: block;
  min-width: 406px;
  padding: 0;
  overflow: hidden;
}
.progress-departed-arrived > li {
  padding: 0;
  /* position: relative; */
}
.progress-departed-arrived > li a {
  font-size: 1.4rem;
  color: #000;
  background-color: #fff;
  max-width: 135px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0;
  width: 132px;
  position: relative;
}
.progress-departed-arrived > li a::before {
  content: "\e411";
  font-family: "Font Awesome 6 Pro";
  position: absolute;
  line-height: 0;
  left: 8px;
  bottom: 0;
  top: 0;
  margin: auto;
  opacity: 0;
  height: 0;
  font-size: 1.8rem;
}
.progress-departed-arrived > li a:hover,
.progress-departed-arrived > li.active a {
  background-color: #f0f0f9;
}
.progress-departed-arrived > li .close {
  position: absolute;
  right: 1px;
  top: 0;
  bottom: 0;
  font-size: 1.3rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  line-height: 0;
  opacity: 0;
  padding: 0 10px;
}
.progress-departed-arrived > li .close:hover {
  background-color: #eee;
}
.progress-departed-arrived > li.active .close,
.progress-departed-arrived > li.active a::before,
.progress-departed-arrived > li:hover .close,
.progress-departed-arrived > li:hover a::before {
  opacity: 1;
}

.remove-first-sort table tr th:first-child,
.remove-first-sort table tr td:first-child,
.remove-second-sort table tr th:nth-child(2),
.remove-second-sort table tr td:nth-child(2) {
  padding-right: 0 !important;
}
.remove-first-sort table tr th:first-child::before,
.remove-first-sort table tr th:first-child::after,
.remove-second-sort table tr th:nth-child(2)::before,
.remove-second-sort table tr th:nth-child(2)::after {
  content: none !important;
}

/* dropdown-with-search */
.dropdown-with-search {
  position: relative;
}
/* .dropdown-with-search::before{
    content: '';
    position: absolute;
    top: -6px;
    left: 24px;
    background: #fff;
    width: 10px;
    height: 10px;
    border-left: 1px solid #cfd7df;
    border-top: 1px solid #cfd7df;
    transform: rotate(45deg);
} */
/* @media (min-width:1200px) {
    .dropdownEnd{
        transform: translate(260px, 47px) !important
    }
} */
.dropdown-with-search ul {
  /* max-height: 200px; */
  overflow: auto;
}
.insideSubmitBtn {
  display: block;
  width: 30px;
  top: 0;
  bottom: 0;
  right: 0px;
  font-size: 1.6rem;
}
/* filterBox */
.custom-dessign-dropdown .dropdownRight {
  position: fixed !important;
  right: -110% !important;
  transform: none !important;
  left: auto !important;
  height: 100%;
  border-radius: 0;
  display: block !important;
  transition: 0.3s;
  top: 0;
}
.custom-dessign-dropdown .dropdownRight.show {
  right: 0 !important;
}
.modal-open-right {
  display: block !important;
  pointer-events: none;
}
.modal-open-right .modal-dialog {
  position: fixed;
  right: -100%;
  top: 0;
  height: 100%;
  bottom: 0;
  max-width: 850px;
  margin: 0;
  transition: 0.3s;
  width: 100%;
}
.modal-open-right.show {
  pointer-events: all;
}
.modal-open-right.show .modal-dialog {
  right: 0;
}
.modal-backdrop {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.offcanvas .close-btn,
.offcanvas .btn-close,
.dropdownRight .dropdown-close-btn {
  position: absolute;
  background-color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  top: 05px;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  outline: none;
}
.offcanvas-start  .close-btn,
.offcanvas-end  .btn-close{
font-size: inherit;
}
.offcanvas-start .close-btn, .offcanvas-start  .btn-close {
  right: -51px;
  border-left: 1px solid #eee;
  border-bottom-right-radius: 5px;
}
.offcanvas-end .close-btn , .offcanvas-end  .btn-close{
  left: -51px;
  border-right: 1px solid #eee;
  border-bottom-left-radius: 5px;
}
.modal-open-right .close-btn , .offcanvas .btn-close{
  opacity: 0;
  visibility: hidden;
}
.modal-open-right.show .close-btn, .offcanvas .btn-close {
  opacity: 1;
  visibility: visible;
}
.modal-open-right .modal-dialog .modal-content {
  height: 100%;
  border-radius: 0;
  max-height: 100%;
}
.modal-open-right .modal-dialog .modal-body {
  overflow: auto;
}
/* .border-bottom.pb-3{
    position: relative;
    z-index: 3;
} */

.departed-dropdown {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-dessign-dropdown .filterBox {
  padding-top: 10px;
  padding-right: 0;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.blurDropdownLayer {
  position: fixed;
  height: 100%;
  width: 100%;
  background: #00000038;
  left: 0;
  top: 0;
  z-index: 2;
  transition: 0.2s;
  cursor: default;
  opacity: 1;
  visibility: visible;
}

.profileDropDownWrap .blurDropdownLayer {
  opacity: 0;
  visibility: hidden;
}
.dropdownRight {
  padding: 0;
}
.dropdownRight .filterBox {
  background-color: #fff;
}

.custom-dessign-dropdown .filterBox .title {
  padding-left: 30px;
}

.dropdown [data-bs-toggle="dropdown"]:hover {
  background-color: #f9f9f9;
}

.filter-form-wrap {
  padding: 0 30px;
  overflow: auto;
}

.custom-dessign-dropdown .filterBox .btn-group {
  padding: 0 30px;
}

.btn:hover {
  opacity: 0.9;
}
.and-or-text {
  position: absolute;
  bottom: -8px;
  left: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.ui-widget.ui-widget-content {
  font-size: 14px;
}
.ui-widget-header .ui-icon,
.ui-icon,
.ui-widget-content .ui-icon {
  background-image: url(../img/ui-icons_222222_256x240.png);
}
.select-filter-wrap .btn {
  aspect-ratio: 1/1;
}
.cursor-normal {
  cursor: auto;
}

select option {
  background: #fff;
  color: #000;
}
select option:hover {
  background-color: #eee;
}

.before-none::before,
.before-none::after {
  content: none;
}
.form-check-input {
  cursor: pointer;
}
.form-check-input:checked {
  background-color: #2c5cc5;
  border-color: #2c5cc5;
}
.selected-column {
  overflow: auto;
  /* max-height: 280px; */
  background-color: #f4f3f3;
}
#y-sortable li,
#x-sortable li,
.y-sortable li,
.x-sortable li {
  cursor: grab;
}
#y-sortable li:active,
#x-sortable li a:active .y-sortable li:active,
.x-sortable li:active {
  cursor: grabbing;
}

.grey-thead {
  background-color: #f1efef;
}

.prority-status {
  width: 7px;
  height: 7px;
  display: inline-block;
  background: #eee;
  border-radius: 50%;
  margin-right: 10px;
}

.prority-status.medium {
  background-color: #f707f7;
}
.prority-status.high {
  background-color: #ff0001;
}
.prority-status.low {
  background-color: #09b476;
}

/* chatBoxWrap */
.chatBoxWrap .chatBox {
  margin-bottom: 10px;
}
.chatBoxWrap .chatboxHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}

.chatBoxWrap .chatBoxBody {
  font-size: 1.6rem;
  padding: 25px;
  border: 1px solid #eee;
}

.dataTables_scroll {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.chatBoxWrap .lightBlue .chatboxHeader {
  background-color: hwb(209deg 71% 11% / 51%);
}
.chatBoxWrap .lightBlue .chatBoxBody {
  background-color: #fff;
}
.chatBoxWrap .lightGreen .chatboxHeader {
  background-color: #eee;
}
.chatBoxWrap .lightGreen .chatBoxBody {
  background-color: #fff;
}
.profileDropDown a {
  font-size: 1.3rem;
}
/* login-page-bg */
.login-page-bg {
  height: 100vh;
  /* background: url(../img/login-bg.jpg) no-repeat; */
  /* background-size: cover; */
}
.login-box {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  box-shadow: 0 0 13px 0px #eeeeee94;
}

.inner-content-update {
  font-size: 1.4rem;
  overflow: auto;
}
.login-wrap .form-control {
  padding: 9px;
  border-radius: 0;
  font-size: 1.6rem;
}

.login-footer {
  background: white;
  margin-bottom: 0;
  padding: 13px 0;
}
.login-footer a {
  color: #000;
}
.login-page-bg {
  position: relative;
  /* padding-bottom: 150px; */
  overflow: auto;
  background: #fff;
  /* color:white; */
}

/* Shipment Board */
.shipment-sidebar {
  min-width: 200px;
  max-width: 220px;
}

.shipment-board-box {
  width: 100%;
  background-color: #f5f7f9;
  max-width: 400px;
  min-width: 400px;
}
.shipment-board-body {
  padding: 10px;
  padding-top: 0;
}
.shipment-board-box .shipment-content-box {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 6px -2px #0e0e0e7a;
}

.shipment-board-header {
  padding: 15px;
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: #f5f7f9;
}
.board-footer-content {
  margin-top: 10px;
}
.shipment-content-box .dropdown-menu {
  z-index: 0;
}

.board-footer-content > li > a {
  font-size: 1.6rem;
  color: rgb(119 119 119 / 80%);
  display: flex;
  align-items: center;
}
.board-footer-content li a i {
  margin-right: 10px;
}
.board-footer-content li .board-member-name {
  width: 25px;
  height: 25px;
  background-color: #d0f2cf;
  border-radius: 50%;
  display: inline-block;
  font-size: 11px;
  font-weight: 800;
  text-align: center;
  line-height: 25px;
  margin-right: 10px;
}

.shipment-board-header h4 {
  margin-bottom: 0;
  color: #000;
  font-size: 1.6rem;
}
.shipment-board-box ul li {
  list-style: none;
  font-size: 13px;
  margin-bottom: 5px;
  color: rgb(0 0 0 / 80%);
}
.shipment-board-box ul li h5 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  color: #2f2e2e;
}
.shipment-board-box ul li:last-child {
  margin-bottom: 0;
}
.shipment-board-box .board-footer-content ul {
  align-items: center;
}
.shipment-board-box .board-footer-content li {
  margin-bottom: 0;
}

.left-panel-name {
  max-width: 260px;
  min-width: 260px;
}
.left-panel-name .shipment-content-box button {
  font-size: 14px;
  color: #2f2e2e;
  background-color: #ffff;
  border: 1px solid #eee;
  border-radius: 4px;
}

.left-panel-name .shipment-content-box button:hover,
.left-panel-name .shipment-content-box button.active {
  background-color: #e5f2fd;
  border-color: #c2dffd;
}

.left-panel-name .shipment-content-box button span {
  font-size: 12px;
  font-weight: 600;
}
.left-panel-name .shipment-content-box {
  box-shadow: none;
}
.zIndex-1 {
  z-index: 1;
}

.dropdown-item.active,
.dropdown-item:active {
  color: inherit;
  background-color: inherit;
}
.dropdown-item {
  padding: 0.5rem 1rem;
  letter-spacing: 0.3px;
  /* white-space: unset; */
}
.saveViewAs {
  margin-top: -64px !important;
  margin-left: -1px !important;
}

.top-strip .dropdown-menu-right {
  left: auto !important;
  right: 0 !important;
  transform: translateX(0) !important;
  top: 38px !important;
}
.redHover-a a:hover {
  color: #fa0000 !important;
}
.coles-supermarket li a {
  white-space: unset;
}
.coles-supermarket .dropdown-menu {
  max-width: 300px;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
select option:hover {
  border-color: #e9ecef;
  background: #e9ecef;
  color: #292728;
}
.name-letter-circle {
  font-size: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  line-height: 20px;
  margin-right: 7px;
}
.name-letter-circle.green {
  background-color: #c8e6c9;
}
.name-letter-circle.purple {
  background-color: #c5cae9;
}
.name-letter-circle.pink {
  background-color: #e8bedb;
}
.name-letter-circle.orange {
  background-color: #ffe1b4;
}
.name-letter-circle.sky-blue {
  background-color: #b2ebf2;
}
.header-search-dropdown {
  max-width: 380px;
}
.header-search-dropdown .dropdown-toggle {
  background-color: #f9f8ff;
}
.header-search-dropdown .dropdown-toggle span {
  font-size: 1.5rem;
}

.min-width-auto {
  min-width: auto;
}
.min-height-auto {
  min-height: auto !important;
}
.column-middle td {
  vertical-align: middle;
}
.invoiceMatched .modal-fullscreen {
  padding: 3% 5%;
}
.input-bg-green {
  background-color: #e4fbde;
  border-color: #5dc442;
}
.grey-btn-active {
  background: #e7e6e6;
}

.folder-view-documents {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}

.folder-view-documents .item {
  min-width: 16%;
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #c3bebe;
  border-radius: 10px;
}
.folder-view-documents .item.file > a i {
  color: #de2d2d;
}
.folder-view-documents .item:hover {
  background-color: #e9f2f9;
}
.folder-view-documents .item > a {
  display: flex;
  font-size: 48px;
  height: 100%;
  flex-direction: column;
  color: #000;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.folder-view-documents .item > a i {
  margin-top: auto;
  margin-bottom: 10px;
}
.folder-view-documents .item > a h4 {
  font-size: 14px;
  margin-bottom: 15px;
}
.folder-view-documents .dropdown-folder button {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.folder-view-documents .dropdown-folder {
  position: absolute;
  top: 10px;
  opacity: 1;
  right: 10px;
  visibility: hidden;
}
.folder-view-documents .item:hover .dropdown-folder {
  opacity: 1;
  visibility: visible;
}

/* horizontal panel*/

.panel-container {
  flex-direction: row;
  /* avoid browser level touch actions */
  xtouch-action: none;
}
@media (min-width: 576px) {
  .panel-container {
    overflow: hidden;
    height: 100%;
  }
  .panel-container .panel-left,
  .panel-container .panel-right {
    overflow: hidden;
    height: 100%;
  }
}
.panel-left {
  flex: 0 0 auto;
  /* only manually resize */
  padding-right: 10px;
  width: 50%;
  min-width: 300px;
  max-width: 50%;
  white-space: nowrap;

  color: white;
}

.resizer {
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  left: auto;
  z-index: 1;
  width: 16px;
  cursor: col-resize;
  background: url(https://raw.githubusercontent.com/RickStrahl/jquery-resizable/master/assets/vsizegrip.png) center center no-repeat #eee;
  border-left: 1px solid;
}

.ui-resizable-e {
  top: 0;
  bottom: 0;
  height: 100%;
  right: 0px;
  cursor: col-resize;
}

.panel-right {
  flex: 1 1 auto;
  /* resizable */
  width: 100%;
  min-width: 200px;
  padding-left: 15px;
  padding-top: 10px;
  padding-right: 10px;
}

.min-width-default {
  min-width: 10rem !important;
  width: auto !important;
  padding: 0 0;
}

/* support-chat-new */

.support-chat-new.chat-system-wrap .chat-left-asidebar {
  min-width: 420px;
}
.support-chat-new.chat-system-wrap .chat-users-list .chat-user {
  padding-left: 15px;
}
.support-chat-new.chat-system-wrap .chat-right-page-body {
  padding: 20px;
}
.toggleTab.nav-pills .nav-link {
  color: #303c8f;
  font-weight: 700;
}
.toggleTab.nav-pills .nav-link.active,
.toggleTab.nav-pills .show > .nav-link {
  background-color: transparent;
  color: #0962be;
  font-weight: 700;
  display: none;
}

.white_space {
  white-space: nowrap;
}

.toggle_button .nav-pills .nav-link.active,
.toggle_button .nav-pills .show > .nav-link {
  background: #007aff;
  border-radius: 0px;
  color: #fff;
}
.toggle_button .nav-pills {
  border: #0c81ff solid 1px;
  border-radius: 0px;
}
.toggle_button .nav-link {
  padding: 0.2rem 1rem;
}
.clone:hover .fa-circle-plus {
  color: #13bf11;
}
.remove:hover .fa-circle-minus {
  color: #ef4047;
}
.clonetr:hover .fa-plus-circle {
  color: #13bf11;
}
.removetr:hover .fa-circle-minus {
  color: #ef4047;
}
.removebox {
  position: absolute;
  top: -2px;
  right: -7px;
  opacity: 0;
}
.removebox a {
  border-radius: 100%;
  width: 14px;
  height: 14px;
  background: #ef4047;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  text-align: center;
  line-height: 16px;
}
.addingfiles:hover .removebox {
  opacity: 1;
}
.round_tabs .nav-pills .nav-link {
  border-radius: 5px;
  padding: 4px 23px;
  border: #ccc solid 1px;
  background: linear-gradient(0deg, rgba(245, 247, 249, 1) 0%, rgba(255, 255, 255, 1) 100%);
  margin: 0px 4px;
  color: #000;
}

.round_tabs .nav-pills .nav-link.active,
.round_tabs .nav-pills .nav-link:hover,
.nav-pills .show > .nav-link {
  background: #e7e6e6;
  color: #000000;
  position: relative;
}

.round_tabs .nav-link.active::before {
  content: " ";
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  margin: 0 auto;
  width: 10px;
  height: 10px;
  background: #e7e6e6;
  border: 1px solid #cccccc;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg);
}

.commantable tbody tr td {
  vertical-align: middle;
}
.extendedTableToggle {
  display: none;
}

/* custom-tabs */
.custom-tabs .nav {
  gap: 12px;
  align-items: center;
}
.custom-tabs > ul > li {
  max-width: fit-content;
  min-width: 122px;
  flex: 1;
  text-align: center;
}
.custom-tabs .nav-link {
  font-size: 1.4rem;
  white-space: nowrap;
  color: #000;
  font-weight: 500;
  background-color: #e7e6e6;
  border-radius: 0;
  padding: 5px 10px;
  text-transform: capitalize;
  width: 100%;
}
.custom-tabs .nav-link.active,
.custom-tabs .nav-link:hover {
  /* border-bottom: 1px solid #eee; */
  background: #303c8f;
  color: #fff;
}

/* Right Popup Size */

.offcanvas.xxl {
  max-width: 80% !important;
}
.offcanvas.xl {
  max-width: 50% !important;
}
.offcanvas.lg {
  max-width: 35% !important;
}
.offcanvas.sm {
  max-width: 25% !important;
}

/* bootstrap modal max Height */

.modal-content {
  max-height: 600px;
}

/* Email Tab css */
.emailnamesbox {
  border-radius: 100%;
  height: 48px;
  width: 48px;
  line-height: 48px;
  color: #fff;
  text-align: center;
  background: #1268b3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.satus {
  width: 8px;
  display: inline-block;
  height: 8px;
  border-radius: 100%;
  margin: 0px 3px;
}

.emailtop .ccname {
  width: 32px;
  display: inline-block;
}

.emailtop .attachemntbox {
  border: #ababab solid 1px;
  padding: 5px 10px 0px 7px;
  width: 100%;
}

.emailtop .attachemntbox figure i {
  color: #0078d7;
  margin-right: 10px;
  padding-top: 4px;
  font-size: 27px;
}

.emailtop .attachemnt_content {
  width: 95%;
}

.emailtop .attachemnt_content p {
  font-size: 12px;
  color: #ababab;
}

.emailtop .attachment {
  width: 32%;
}

.satusdate {
  width: 22%;
}

.assignename {
  display: flex;
  width: 100%;
}

.satuslog {
  width: 17px;
  position: relative;
}
.statuschange_inner {
  max-height: 400px;
  overflow-y: scroll;
}
.statusrow {
  width: 75%;
  margin-bottom: 20px;
}
.satuslog::before {
  content: "";
  width: 9px;
  height: 9px;
  display: inline-block;
  position: absolute;
  left: -3px;
  border-radius: 100%;
  background: #000;
  top: 0px;
  right: 0px;
  margin: auto;
  z-index: 99;
  box-shadow: 0 0px 0px 7px #d5def3;
}

.satuslog::after {
  content: "";
  display: block;
  width: 1px;
  height: 136%;
  background: #7f7f7f;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
}
.statusrow:last-child .satuslog::after {
  display: none;
}
.satusdots {
  background-color: #000;
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 3px;
  border-radius: 100%;
}
@media (max-width: 600px) {
  .emailtop .attachment {
    width: 48%;
  }
  .statusrow {
    width: 100%;
  }
  .satusdate {
    width: 30%;
  }
}

@media (max-width: 400px) {
  .emailtop .attachemnt_content p {
    white-space: nowrap;
    width: 66px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Email box */

.emailwerap {
  display: flex;
}
.lefttree {
  min-width: 210px;
  border-right: #ccc solid 1px;
}
.emailbody h1 {
  color: #000000;
  font-size: 18px;
  font-weight: 800;
}
.emailbody {
  padding: 15px 20px;
}

.emailteablewrap {
  max-height: 94vh;
  overflow: auto;
}
.emailteablewrap table.inboxMails {
  min-width: 1600px;
}
.emailteablewrap table thead tr th {
  white-space: nowrap;
  background: #f0f0f0;
  padding: 12px;
  font-weight: 700;
  font-size: 13px;
  border: none;
  border-bottom: #e1e1e1 solid 1px;
}
.emailteablewrap table tbody tr td {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  padding: 10px 7px;
  color: #6b6b6b;
  border-bottom: #e1e1e1 solid 1px;
  vertical-align: middle;
}
.emailteablewrap table tbody tr:hover {
  background: #e6f2fa;
  transition: 0.5s;
  color: #000000;
}

.emailteablewrap table tbody tr td i {
  font-size: 16px;
  vertical-align: middle;
  padding-left: 7px;
}
.emailteablewrap table tbody tr td .fa-flag-pennant {
  color: #ababab;
}
.emailteablewrap table tbody tr td .fa-flag-pennant:hover {
  color: #ff0000;
}
.tablestickey {
  max-height: 923px;
  overflow: scroll;
}
.tablestickey table thead tr th {
  position: sticky;
  top: 0;
  z-index: 10;
}
.emailteablewrap table tbody tr td:nth-child(2) {
  padding-left: 40px;
  position: relative;
}
.emailteablewrap table tbody tr td:nth-child(2) a {
  position: absolute;
  left: 0;
}

.replyrowem ul {
  display: flex;
}
.replyrowem ul li {
  font-size: 13px;
}
.replyrowem ul li a {
  color: #a1a1a1;
  padding: 7px 10px;
  font-size: 14px;
  white-space: nowrap;
}
.replyrowem ul li a:hover {
  background: #e6f2fa;
}
.replyrowem ul li:first-child a {
  padding-left: 0px;
}
.eemmasterwarp h3 {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 0 10px 0;
  margin-bottom: 0px;
}

.usernamembox {
  padding-left: 15px;
}

.attachment_inner .savearrow {
  color: #777777;
  font-size: 12px;
}
.atthcicon .fas {
  color: #0078d7;
  font-size: 32px;
}

.attachment_inner {
  border: #ababab solid 1px;
  padding: 7px 10px;
  display: flex;
  margin-right: 7px;
  margin-bottom: 7px;
}
.attachemntwrap {
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  /* max-height: 120px; */
  overflow-y: auto;
}
.attachment_name p {
  font-size: 12px;
  margin-bottom: 0px;
  padding: 0px 15px;
}
.attachment_name p span {
  display: block;
  font-size: 12px;
}

.emailmaibody {
  border: #ccc solid 1px;
  padding: 10px 10px 55px 10px;
}
.emailmaibody textarea {
  width: 100%;
  min-height: 300px;
  resize: none;
  border: none;
  padding: 20px 0 0 10px;
}
.signaureslgoo {
  padding: 0px 0px 0 30px;
}

.signaureslgoo h3 {
  font-size: 19px;
  font-weight: bold;
  padding-bottom: 15px;
}

.conteact_sign ul li {
  display: block;
}

.conteact_sign {
  border-left: #c6c6c6 solid 1px;
  padding-left: 25px;
}

.conteact_sign ul li {
  padding: 3px 0px;
  font-weight: 600;
}

.conteact_sign ul li span {
  font-weight: normal;
  padding-left: 5px;
}

.conteact_sign ul li a {
  color: #0070c0;
}

/* .mail-wrapper{
 overflow: hidden;
  height:100%;
  margin:0;
  padding:0;
  width:100%;
} */
.emailbody {
  width: 50%;
  height: 100%;
}
.right-view-email {
  height: 100%;
}

/* shipment-dashboard-wrapper */

.shipment-dashboard-wrapper .common-box {
  box-shadow: 0 0 6px 1px #eee;
  border-radius: 4px;
  padding: 15px 25px;
}

.shipment-dashboard-wrapper .top-box i {
  font-size: 6rem;
  margin-right: 20px;
}

.shipment-dashboard-wrapper .common-box [data-bs-toggle="collapse"] {
  opacity: 0;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}
.shipment-dashboard-wrapper .common-box [data-bs-toggle="collapse"].collapsed {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}
.shipment-dashboard-wrapper .common-box:hover [data-bs-toggle="collapse"] {
  opacity: 1;
}

.shipment-dashboard-wrapper .common-box .accordion-collapse .box-body {
  border-top: 1px solid #cbcbcb;
}
.shipment-dashboard-wrapper .common-box .accordion-collapse .box-body .item {
  border-bottom: 1px solid #cbcbcb;
  padding: 5px 0;
}
.shipment-dashboard-wrapper .common-box .accordion-collapse .box-body .item h5 {
  margin-bottom: 0;
  color: #303c8f;
  font-size: 1.4rem;
}

/* activity-timeline */
.activity-timeline {
  padding-top: 20px;
}
.activity-timeline .timeline-item {
  display: flex;
  min-height: 60px;
  align-items: baseline;
  position: relative;
  font-size: 12px;
  padding-bottom: 10px;
}
.activity-timeline .timeline-item p,
.activity-timeline .timeline-item h5 {
  margin-bottom: 0;
}
.activity-timeline .timeline-item h5 {
  font-size: 12px;
  white-space: nowrap;
  font-weight: 700;
}
.activity-timeline .timeline-item date,
.activity-timeline .timeline-item span {
  color: #b7b6b6;
}
.shipment-dashboard-wrapper .common-box a:hover,
.shipment-dashboard-wrapper .common-box a:hover h5 {
  color: #ef4047 !important;
}
.activity-timeline .circle-indicator {
  margin-right: 30px;
  margin-top: 4px;
  margin-left: 22px;
  box-shadow: 0 0 0px 6px #e7e6e6;
}
.activity-timeline .circle-indicator::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #e7e6e6;
  margin-left: 3px;
  margin-top: 12px;
}
.timeline-item:nth-last-child(1) .circle-indicator::after {
  content: none;
}
.activity-timeline .pulse-animate {
  animation-name: timeline_dot_pulse;
  animation-duration: 0.5s;
  /* animation-timing-function: ease; */
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes timeline_dot_pulse {
  to {
    box-shadow: 0 0 0px 3px #eeee;
  }
  from {
    box-shadow: 0 0 0px 6px #e7e6e6;
  }
}
/* .ui-sortable-helper{
    background:#ef4047 !important;
    color: #fff !important;
}
.ui-sortable-helper *{ color: #fff !important;} */
.ui-sortable-handle {
  cursor: move;
}
.reportsWrap .list-group-item .favourite.active i {
  font-weight: 600;
}

.custom-edit-btn .edit-btn {
  display: none;
  box-shadow: none;
}
.custom-edit-btn:hover .edit-btn {
  display: block;
}
.custom-edit-btn .edit-btn:hover {
  color: #ef4047;
}
.reportsWrap li a:hover {
  color: #ef4047 !important;
}
.edit-report-input {
  position: absolute;
  left: 30px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  width: 70%;
  padding-right: 10px;
}
.edit-report-input.active {
  opacity: 1;
  visibility: visible;
}
.reportsWrap .list-group .list-group-item:hover {
  background: #eef5fa;
  color: #000;
}
.reportsWrap .list-group .list-group-item:hover .edit-report-input {
  background: #eef5fa;
}
.dropdown-select {
  transform: none !important;
  margin-top: 32px !important;
}

/* Mailbox */
.aside-bar.email-inbox-aside {
  display: none;
  min-width: 220px;
  border-right: 1px solid #eee;
}
.aside-bar.mail-aside-toggle {
  display: block;
}
.email-inbox-aside .aside-body ul li button {
  font-weight: 500;
  display: block;
  width: 100%;
  text-align: left;
}
.mailbox-top-strip button {
  color: #232c69;
  font-weight: 500;
}
.email-inbox-aside .aside-body ul li button:hover,
.email-inbox-aside .aside-body ul li button.active,
.mailbox-top-strip button:hover {
  color: #cd0000;
}

.top-strip .mobileSidebarToggle, #menuBar-toggle .hamburger{
  display: none;
}
#menuBar-toggle .mobileSidebarToggle{
  display: block;
}

.email-inbox-aside .aside-body ul li.list-target-a a[aria-expanded="true"] > .fa-light {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.email-inbox-aside .aside-body ul li.list-target-a li .badge {
  color: #747474;
  position: absolute;
  right: 0;
  top: 4px;
}
.left-box-email {
  border-right: 1px solid #eee;
  overflow: hidden;
}
.left-box-email {
  min-width: 350px;
}
.name-subject-from .text-truncate {
  max-width: 280px;
}
.inner-profile-circle {
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  background-color: #6c757d;
  color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  position: relative;
  margin-right: 10px;
}
.inner-email-item {
  padding: 10px 7px;
  border-bottom: 1px solid #eee;
}
.inner-email-item:hover,
.inner-email-item.active {
  background-color: #f8f9fa;
}
.name-subject-from {
  cursor: pointer;
  margin-right: 3px;
}
.inner-email-item:hover .inner-profile-circle,
.inner-email-item.active .inner-profile-circle {
  background-color: transparent;
}
.inner-profile-circle .form-check {
  position: absolute;
  text-align: center;
  opacity: 0;
}
.inner-profile-circle.active {
  background-color: transparent;
  font-size: 0;
}
.inner-profile-circle.active .form-check {
  opacity: 1;
}

.inner-email-item:hover .inner-profile-circle .form-check,
.inner-email-item.active .inner-profile-circle .form-check {
  opacity: 1;
}
.hoverIcons {
  position: absolute;
  pointer-events: none;
  padding-left: 5px;
  right: 0;
}
.inner-email-item:hover .hoverIcons {
  background-color: #f8f9fa;
}
.hoverIcons button {
  pointer-events: all;
  opacity: 0 !important;
  visibility: hidden;
}

.inner-email-item:hover .hoverIcons button {
  opacity: 1 !important;
  visibility: visible;
  background-color: #f8f9fa;
}

.hoverIcons button.active {
  opacity: 1 !important;
  visibility: visible;
  background-color: #f8f9fa;
}

.hoverIcons button.active i {
  font-weight: bold;
  color: #cd0000;
}

.edit-btn:hover i {
  color: #2e3772;
}
.delete-btn:hover i,
.status-ban-btn:hover i {
  color: #cd0000;
}
.status-check-btn:hover i {
  color: #13bf11;
}
.inner-email-item:hover .delete-btn {
  opacity: 1;
}
.date-stamp {
  background-color: #fff;
}
.inner-email-item:hover .date-stamp,
.inner-email-item.active .date-stamp {
  background-color: #f8f9fa;
}

/* "right-box-email */
.right-box-email {
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}
.right-box-email-header {
  padding: 8.5px 10px;
  border-bottom: 1px solid #eee;
}
.right-box-email-body {
  overflow: auto;
  padding: 20px;
  height: 100%;
}

/* attachemnt_dropdown */
.attachemnt_dropdown li {
  display: flex;
  padding: 5px 8px;
}
.attachemnt_dropdown li:hover {
  background-color: #f8f9fa;
}
.attachemnt_dropdown li .attched-file {
  min-width: 35px;
  width: 35px;
  max-width: 35px;
  display: flex;
  align-items: center;
}
.attachemnt_dropdown li h4 {
  font-size: 13px;
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-bottom: 0;
}
.attachemnt_dropdown li .attched-file i {
  font-size: 26px;
  color: #0078d7;
}
.attachemnt_dropdown li .view-download {
  opacity: 0;
  visibility: hidden;
}
.attachemnt_dropdown li:hover .view-download {
  opacity: 1;
  visibility: visible;
}

.usernameem {
  display: flex;
}
.usernamembox p {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #444444;
}
.roundboxnameem a {
  width: 40px;
  height: 40px;
  background: #1268b3;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  line-height: 40px;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  text-transform: uppercase;
}

.colorboxem {
  width: #38bc1a;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  display: inline-block;
}
.usernamembox ul li {
  font-size: 13px;
  display: flex;
  padding: 2px 0;
}
.usernamembox ul li span {
  min-width: 30px;
  display: inline-block;
}
.usernamembox ul li a {
  color: #444444;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.attachment_name p {
  font-size: 12px;
  margin-bottom: 0px;
  padding: 0px 15px;
}
.attachment_name p span {
  display: block;
  font-size: 12px;
}

/* new-mail-compose */
.new-mail-compose {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  overflow: auto;
  /* display: none; */
}
.new-mail-compose.active {
  display: block;
}
.to-cc-bcc-subject .btn {
  width: 90px;
}

.to-cc-bcc-subject .select2-container--default .select2-selection--multiple {
  border: 0;
  width: 100%;
  border-bottom: 1px solid #e0e4e8;
  border-radius: 0;
}
.to-cc-bcc-subject .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.to-cc-bcc-subject .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  border-color: #dee2e6;
}
/* .select2-container--open .select2-dropdown--below{
    max-width: 280px !important;
} */
.select2-dropdown {
  border-color: #dee2e6;
}

.to-cc-bcc-subject .ck.ck-editor {
  height: 100% !important;
}

.new-mail-compose .ck.ck-editor {
  position: relative;
  height: 100% !important;
  display: flex;
  flex-direction: column;
}

.new-mail-compose .ck-editor__editable,
.new-mail-compose .ck.ck-editor__main {
  min-height: 150px;
  max-height: 100%;
  height: 100%;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}
.select2-container {
  width: 100% !important;
}
.right-box-email-main {
  overflow: auto;
}

.split__left {
  width: 50%;
  background-color: #aacd6e;
}

.split__right {
  flex: 1;
  background-color: #c5c6b6;
}

.split__bar {
  background-color: #3c3530;
  width: 15px;
  height: 100%;
  cursor: col-resize;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #ced4da;
}

.select2-container .select2-selection {
  height: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.select2-container .select2-selection[aria-expanded="true"] {
  height: auto;
}

/* badge-bg colors  */
.badge-bg-green {
  background-color: #e0f7f4;
  color: #1ebb8c;
}
.badge-bg-red {
  background-color: #feebf0;
  color: #da496c;
}
.badge-bg-yellow {
  background-color: #fef7e0;
  color: #e58c33;
}

/* invoice-wrapper */
.invoice-wrapper {
  width: 960px;
  padding: 60px;
  border: 1px solid #e2e8f0;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.industry-logo {
  width: 100px;
  overflow: hidden;
}

.industry-logo img {
  width: 100%;
  max-width: 100px;
}

.invoice-wrapper td {
  vertical-align: middle;
  font-size: 12px;
  font-weight: 500;
  padding: 13px;
}

/* dashboard_Page */
.dashboard_Page .card {
  min-height: 220px;
  border-radius: 18px;
}

.dashboard_Page .card h5 {
  font-size: 6rem;
}

.dashboard_Page .card p {
  font-size: 14px;
}
.card-bg-green {
  background-color: #f0fdf4;
  color: #166534;
}
.card-bg-blue {
  background-color: #eef2ff;
  color: #3730a3;
}
.card-bg-gray {
  background-color: #f1f5f9;
  color: #64748b;
}

/* verticle-tabs */
.verticle-tabs .nav li button {
  border: 0;
  text-align: left;
  display: block;
  width: 100%;
  margin-bottom: 10px;
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 9px;
}
.verticle-tabs .nav li button i {
  width: 20px;
}
.verticle-tabs .nav li button.active,
.verticle-tabs .nav li button:hover {
  background: #f6f6f6;
}

.geoLocationLog td {
  padding: 5px 15px !important;
}

.p-paginator .p-paginator-current {
  margin-right: auto;
}

.p-paginator {
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
}

.p-paginator .pi,
.p-paginator .p-paginator-pages .p-paginator-page {
  font-size: 14px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid #575858;
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

/* React Prime css */
.p-dropdown-panel {
  z-index: 1055 !important;
}

.p-dropdown {
  font-weight: 400;
  padding: 7px 5px;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  -o-border-radius: 0.4rem;
}
.p-dropdown-label {
  padding: 0;
  font-size: 14px;
}

.p-paginator .p-dropdown {
  height: auto;
}

.p-datatable .p-datatable-thead > tr > th{
  font-weight: 700;
}

.p-datatable .p-datatable-tbody > tr > td{
  font-weight: 500;
  color: #000;
}
.p-datatable .p-datatable-thead > tr > th .p-column-title{
    color: #000;
    font-size: 1.3rem !important;
}
.p-datepicker-title > * {
  font-size: 14px;
}
.table-bordered td{
  border-width: 1px;
}
.setup__page .p-tabview-nav-container{
  display: none;
}
.p-input-icon-right .pi{
  font-size: 14px;
}
.from-error-span{
  color: red;
  font-weight: 500;
  margin-top: 5px;
  display: block;
  font-size: 12px;
}
.offcanvas-backdrop{
  height: 100%;
}
.p-multiselect-header::after {
  content: 'Select All';
  position: absolute;
  left: 36px;  
}

 