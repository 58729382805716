@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
.main-login-page {
  position: fixed;
  height: 100%;
  overflow: auto;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* background-image: url(../img/login-2-right.jpg), url(../img/login-2-right.jpg); */
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-position: left bottom, right bottom;
  background-size: 20%, 20%;
}
.loginWrap {
  flex-grow: 1;
}
.login_container {
  width: 100%;
  max-width: 1330px;
  padding: 0 15px;
  margin: auto;
}

header {
  padding: 30px 0;
}

/* login-Announcement */

.login-Announcement {
  position: relative;
  z-index: 1;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  padding-top: 0;
}

.login-content {
  min-width: 535px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  padding: 40px 45px;
  box-shadow: 0 0 17px 2px #eee;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background: #fff;
  max-width: 535px;
}
.login-Announcement h1 {
  margin: 0;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 35px;
  color: #000;
}

.login-Announcement .form-group {
  margin-bottom: 20px;
}
.login-Announcement .form-group label {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}
.login-Announcement .form-group:nth-last-of-type(2) {
  margin-bottom: 20px;
}
.login-Announcement .form-control {
  width: 100%;
  display: block;
  border: 1px solid #d6d0d0;
  padding: 12px 19px;
  border-radius: 8px;
  color: #000;
  outline: none;
  font-size: 16px;
}

.login-Announcement .form-control:focus {
  box-shadow: 0 0 10px 0 #eee;
}

.remember-forgot {
  display: flex;
  justify-content: space-between;
}
.remember-checkbox {
  display: flex;
}
.remember-checkbox label {
  color: #000;
  font-weight: 500;
  cursor: pointer;
  margin-left: 5px;
  vertical-align: middle;
}
.remember-checkbox label::selection {
  color: #000;
}
.remember-checkbox input {
  width: 17px;
}

.remember-forgot a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
}

.remember-forgot a:hover,
.remember-checkbox label:hover {
  color: #cd2027;
}

.submitBTN,
.otherLogin {
  width: 100%;
  background: #0aac19 !important;
  padding: 12px 0 !important;
  border: 0;
  color: #fff;
  font-size: 16px;
  margin-top: 30px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 0 10px 0 #eee;
}

.submitBTN:hover {
  opacity: 0.8 !important;
}

.custom-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.custom-checkbox label {
  position: relative;
  cursor: pointer;
  font-weight: 600;
}

.custom-checkbox label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #717171;
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 5px;
}
.custom-checkbox input:checked + label:before {
  background-color: #029a06;
  border: 1px solid #029a06;
}
.custom-checkbox input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-checkbox input + label {
  line-height: 0;
}

.or_sign_with {
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  align-items: center;
  font-size: 13px;
  color: #b9b5ce;
  font-weight: 500;
}
.or_sign_with::before,
.or_sign_with::after {
  content: "";
  width: 25%;
  height: 2px;
  background-color: #b9b5ce;
  display: block;
}

.otherLogin {
  margin-top: 0;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #d6d0d0;
  color: #000;
  font-weight: 500;
  display: inline-block;
  width: fit-content;
  padding: 9px 18px;
}

.loginBTNBox .btn {
  font-size: 30px;
}
/* content-wrap */

.content-wrap {
  max-width: 550px;
  margin-left: auto;
  flex-grow: 1;
  padding-left: 30px;
  margin-right: 100px;
  overflow: auto;
  max-height: 680px;
}
.content-wrap::-webkit-scrollbar {
  background-color: #eee;
  width: 8px;
}

.content-wrap::-webkit-scrollbar-track {
  background: #eee;
}
.content-wrap::-webkit-scrollbar-thumb {
  width: 5px;
  background: #cd2027;
}
.main-heading {
  font-size: 30px;
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
}
.text-color-red,
.content-wrap .readMore:hover {
  color: #cd2027 !important;
}
.text-color-green {
  color: #0aac19 !important;
}
.sub-heading {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin-top: 20px;
}

.content-wrap p {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-top: 15px;
}

.content-wrap .readMore {
  font-size: 16px;
  color: #3644c7;
  font-weight: 600;
  display: inline-block;
  margin-top: 25px;
}

.content-wrap ul li {
  display: flex;
  margin-top: 18px;
  color: #000;
  font-weight: 500;
}
/* .content-wrap ul li:before {
  content: url(../img/check_icon-back.png);
  margin-right: 15px;
} */

/* login_footer */
.login_footer {
  padding: 0 80px;
}
.login_footer ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 10px;
}

.login_footer ul li a {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  padding-right: 8px;
  margin-right: 8px;
  border-right: 1px solid rgb(31, 29, 29);
}
.login_footer ul li a:hover,
.or_sign_with a:hover,
.impexDocs-sso .login-content a:hover,
.perform-action a:hover,
.confirmation-page a:hover {
  color: #cd2027;
}

.login_footer ul li:last-child a {
  border-right: 0;
}

.login_footer p {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.or_sign_with a {
  text-decoration: none;
  color: #2410a1;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.or_sign_with a::before {
  content: "";
  border-width: 7px;
  border-style: solid;
  border-color: transparent #2410a1 transparent transparent;
  position: absolute;
  left: -20px;
  top: 3px;
}
.or_sign_with a:hover::before {
  border-right-color: #cd2027;
}
.forgotPage .or_sign_with {
  padding: 40px 20px;
}

.forgotPage .submitBTN {
  margin-top: 0px;
}
.text-center {
  text-align: center;
}

/* update-account-info */
.update-account-info {
  display: flex;
  flex-direction: column;
}
.update-account-info .login_footer {
  position: absolute;
  bottom: 0;
}
.update-account-info h1 {
  font-size: 28px;
}
/* .update-account-info {
  background: url(../img/login-center-bg.png) center center no-repeat;
  justify-content: center;
} */
.update-account-info .submitBTN {
  margin-top: 0;
}

.impexDocs-sso h1 {
  text-align: center;
}
.login-info-alert {
  display: flex;
  background-color: #fff5d7;
  padding: 20px;
  border-radius: 15px;
  align-items: center;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.login-content .login-info-alert p {
  margin-left: 10px;
  color: #664d03;
  font-size: 14px;
  margin-top: 0 !important;
  font-weight: 500;
}
.impexDocs-sso .login-content p {
  margin-top: 25px;
  font-size: 14px;
  font-weight: 500;
}
.impexDocs-sso .login-content a {
  color: #2410a1;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}
.impexDocs-sso .login-content p + a {
  margin-top: 20px;
}

/* email_verification */
.login-method {
  min-height: 500px;
}
.email_verification {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
  width: 100%;
  position: relative;
}
.email_verification:last-child {
  margin-bottom: 0;
}
.email_verification img {
  margin-right: 10px;
}
.email_verification div {
  flex-grow: 1;
}
.email_verification a {
  text-decoration: none;
  display: block;
}
.email_verification h5 {
  color: #2410a1;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  margin-bottom: 10px;
}
.email_verification p {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}
.email_verification:after {
  content: "";
  width: 10px;
  height: 10px;
  border: 1px solid;
  border-top: 0;
  border-right: 0;
  rotate: -135deg;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}

.email_verification:hover::after {
  translate: 10px 0;
}

/* confirmation-page */
.confirmation-page h4 {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  line-height: 28px;
  text-align: center;
}
.confirmation-page h4 a {
  font-size: 20px;
}
.confirmation-page p {
  font-size: 14px;
  font-weight: 500;
  margin: 20px 0;
}

.confirmation-page a {
  color: #2410a1;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}
/* mobile-authenticator */
.mobile-authenticator p {
  font-weight: 500;
}
.mobile-authenticator h5 {
  font-size: 14px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 20px;
}

/*  perform-action */
.perform-action h1 {
  font-weight: 700;
}
.perform-action p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}
.perform-action a,
.account-updated a {
  color: #2410a1;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}

/* account-updated */
.account-updated h1 {
  font-weight: 400;
  font-size: 20px;
}
.account-updated p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #000;
}
.login-2-index {
  background: url(../img/login-2-right.jpg) no-repeat right top;
  height: 100%;
}
.login-2-index::before {
  content: "";
  width: 50%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  z-index: -1;
}
.login-2-index .login-content {
  margin-left: auto;
  padding-right: 90px;
  width: 50%;
  border-radius: 0;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  min-width: auto;
  max-width: unset;
}

.login-2-index .login_container {
  width: 100%;
  max-width: 1190px;
  padding: 0 15px;
  margin: auto;
}
.login-2-index .content-wrap {
  flex-grow: 1;
  padding-left: 12%;
  margin-left: 0;
  padding-right: 5%;
  margin-top: -225px;
  width: 50%;
  max-width: unset;
}
.inner-login-2 {
  max-width: 430px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.inner-login-2 .or_sign_with {
  padding-left: 0;
  padding-right: 0;
}
.inner-login-2 .Copyright {
  font-size: 12px;
  font-weight: 600;
}

.login-2-index .content-wrap h2 {
  color: #fff !important;
}
.login-2-index .content-wrap h3 {
  color: #fff;
  margin-top: 35px;
}
.login-2-index .content-wrap p {
  color: #fff;
  font-weight: 300;
}
/* .login-2-index .content-wrap {
  position: fixed;
  right: 0;
  width: 50%;
  max-width: unset;
  top: 0;
  bottom: 0;
  padding-right: 250px;
  margin-top: 150px;
} */
.login-2-link {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 101;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
}
.login-2-link:hover {
  color: #fff;
  opacity: 0.8;
}
@media (max-width: 1400px) {
  .login_footer {
    position: relative;
  }
  .login-2-index .login-content {
    width: 50%;
    padding-right: 5%;
  }
  .login-2-index .content-wrap {
    width: 60%;
    padding-left: 5%;
  }
  .login-2-index::before {
    width: 38%;
  }
}
@media (max-width: 1200px) {
  header {
    padding: 20px 0;
  }
  .content-wrap {
    padding-bottom: 30px;
  }
  .login-content {
    padding: 30px;
    min-width: auto;
    width: 50%;
  }
  .content-wrap {
    margin-right: 0;
    min-width: auto;
    width: 50%;
  }
  .main-login-page {
    background-image: none;
  }
}

@media (max-width: 991px) {
  .login_container {
    padding: 0 50px;
  }
  .login_footer {
    padding: 0 15px;
  }
  .login-Announcement {
    display: block;
  }
  .login-content {
    min-width: auto;
    width: 100%;
    max-width: 100%;
  }
  .login-content.box-remove-mobile {
    padding: 0;
    box-shadow: none;
  }
  .content-wrap {
    margin-top: 30px;
    margin-right: 0;
    padding-left: 0;
    min-width: auto;
    width: 100%;
    max-width: 100%;
  }
  .login-Announcement .form-group {
    margin-bottom: 15px;
  }
  .or_sign_with {
    padding: 20px;
  }
  .main-heading {
    font-size: 26px;
  }
  .sub-heading {
    font-size: 18px;
  }
  .content-wrap p {
    font-size: 14px;
  }
  .content-wrap ul li {
    margin-top: 15px;
    font-size: 14px;
  }

  .login-2-index .login-content {
    width: 100%;
    padding-right: 5%;
  }
  .login-2-index .content-wrap {
    width: 100%;
    padding-left: 5%;
  }
  .login-2-index::before {
    content: none;
  }
  .login-2-index {
    background: no-repeat;
  }
  .inner-login-2 {
    margin: auto;
    max-width: 500px;

    padding: 30px !important;
  }
  .login-2-index .login-content {
    padding-right: 0;
  }
  .login-2-index .content-wrap {
    background: url(../img/login-2-right.jpg) no-repeat bottom center;
    padding-top: 30px;
    height: 620px;
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
    background-size: cover;
    margin-top: 20px;
  }
  .Copyright {
    padding-top: 20px;
  }
  .inner-login-2 .Copyright {
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .update-account-info .login_footer {
    position: static;
    bottom: 0;
    margin-top: 30px;
  }
}
@media (max-width: 576px) {
  .login_container {
    padding: 0 15px;
  }
  .login-Announcement h1 {
    font-size: 26px;
    margin-bottom: 20px;
  }
  .forgotPage .submitBTN {
    margin-top: 10px;
  }
  .login-Announcement .form-control {
    padding: 10px 12px;
    border-radius: 6px;
    font-size: 14px;
  }
  .login-Announcement .form-group:last-of-type {
    margin-bottom: 0;
  }
  .update-account-info .submitBTN {
    margin-top: 20px;
  }
  .confirmation-page {
    text-align: center;
  }
  .update-account-info .login-Announcement {
    min-height: auto;
  }
  .inner-login-2 {
    margin: auto;
    max-width: 500px;
    padding: 15px !important;
    width: 100%;
  }
  .login-2-index .content-wrap h3 {
    margin-top: 10px;
  }
}

.p-input-icon-right .pi{
  font-size: 14px;
}