.page-wrapper {
    margin-top: 50px;
}
.inner-link li {
    list-style: none;
    width: 20%;
    text-align: center;
}

.inner-link li a {
    display: block;
    border-radius: 5px;
    text-decoration: none;
    color: #000;
    padding: 20px;
    font-size: 20px;
}

.inner-link li a:hover {
    background-color: #eee;
}

@media(max-width:576px) {
    .inner-link li {
        width: 100%;
        margin-bottom: 10px;
    }
}