@media (max-width: 1400px) {
  .departed-dropdown {
    width: auto;
  }
}

@media (max-width: 1200px) {
  .profileDropDownWrap .blurDropdownLayer {
    opacity: 1;
    visibility: visible;
  }
  .panel-container .resizer,
  .panel-container .ui-resizable-se {
    display: none !important;
  }
  .notification-header .dropdown-body {
    max-height: 100%;
  }
  .profileDropDown,
  .dropdown-slide-right {
    position: fixed !important;
    display: block !important;
    height: 100%;
    top: 0 !important;
    transform: none !important;
    max-width: 320px;
    width: 100%;
    left: auto !important;
    right: -100% !important;
    transition: 0.3s;
  }
  .notification-header {
    min-width: auto;
    display: flex !important;
    flex-direction: column;
  }
  .profile-name-head {
    padding: 6px 0;
  }
  .notification-name-head {
    padding: 13px;
  }
  .header-right-links .badge {
    width: 15px;
    height: 15px;

    line-height: 16px;
    left: 3px;
    top: 5px !important;
  }
  .notification-header {
    min-width: auto;
    display: flex !important;
    flex-direction: column;
  }
  .profileDropDown.show,
  .dropdown-slide-right.show {
    right: 0 !important;
  }
  .profileDropDown li a {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 8px 0;
    padding-left: 10px;
    text-decoration: none;
    align-items: center;
    color: #000;
    position: relative;
    font-size: 1.5rem;
    border-bottom: 1px solid #eee;
  }
  .profileDropDown li a i {
    font-size: 1.6rem;
    width: 35px;
    color: #000;
    text-align: center;
    vertical-align: text-bottom;
  }
  .fixed-header-content,
  #template-default-page.menu-closed .fixed-header-content {
    margin-left: 0;
  }
  .sidebar-logo {
    background: #fff;
  }
  .main-nav nav > ul > li > a {
    padding: 7px 9px;
    text-align: center;
  }
  .main-nav-mega-menu.shipment-maga-nav {
    min-width: 610px;
  }
  .main-nav ul {
    background-color: #fff;
  }
  .main-nav ul li a span {
    opacity: 1;
    visibility: visible;
    margin-left: 0px;
  }
  #menuBar-toggle {
    z-index: 3;
    height: 100%;
    left: -100%;
  }
  .menu-closed #menuBar-toggle {
    left: 0%;
  }

  .aside-bar::after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000000bd;
    position: fixed;
    z-index: -1;
    left: 0;
    right: 0;
    display: none;
  }

  .menu-closed .aside-bar::after {
    display: block;
  }
  .page-wrapper-with-aisde {
    position: relative;
    display: block;
  }
  .menu-closed #menuBar-toggle {
    min-width: 235px;
    max-width: 235px;
    left: 0;
  }
  .aside-bar .aside-header .asidebar-toggle {
    position: fixed;
    display: block;
    left: 0;
    background: #eeee;
    height: 44px;
    margin-top: 80px;
    transform: rotate(180deg);
  }
  .aside-bar.active .aside-header .asidebar-toggle {
    position: static;
    height: auto;
    margin-top: 0;
    transform: rotate(0deg);
    background: none;
  }
  .shipment-sidebar {
    position: fixed;
    background: #fff;
    height: 100%;
    z-index: 4;
    overflow: auto;
    top: 0;
  }
  .folder-view-documents {
    gap: 20px;
  }
  .folder-view-documents .item {
    flex: 1;
  }
  .support-chat-new.chat-system-wrap .chat-left-asidebar {
    min-width: 490px;
  }

  .offcanvas.xxl,
  .offcanvas.xl,
  .offcanvas.lg,
  .offcanvas.sm {
    max-width: 80% !important;
  }

  .main-footer {
    padding: 10px 0;
  }

  .aside-bar.email-inbox-aside {
    position: fixed;
    background: #fff;
    z-index: 3;
    height: 100%;
    transition: 0.3s;
    top: 0;
    left: -100%;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    display: block;
    -o-transition: 0.3s;
  }
  .email-inbox-aside:after {
    content: "";
    height: 100%;
    width: 100%;
    background-color: #000000b0;
    position: fixed;
    z-index: -1;
    left: 0;
    right: 0;
    display: none;
  }
  .email-inbox-aside.mail-aside-toggle:after {
    display: block;
  }
  .aside-bar.mail-aside-toggle {
    display: block;
    left: 0;
  }
  .mail-aside-toggle:after {
    content: "";
    background: #0000008a;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

@media (max-width: 991px) {
  .main-nav nav > ul > li > a {
    text-align: left;
  }
  .departed-dropdown {
    max-width: 200px;
    width: 100%;
  }
  .main-nav-mega-menu {
    min-width: auto !important;
  }

  .main-nav nav {
    overflow: auto;
    height: inherit;
    padding-bottom: 56px;
    background-color: #fff;
  }
  .main-nav nav:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    height: 100%;
    background-color: rgb(0 0 0 / 50%);
    z-index: -2;
  }
  .main-nav.active nav:after {
    opacity: 1;
    visibility: visible;
  }
  .main-nav ul {
    border-bottom: 0;
  }
  .main-nav ul li a {
    text-align: left;
    font-size: 15px;
    padding: 8px 20px;
    border-right: 0;
  }
  .main-nav ul li a i {
    margin-right: 15px;
    width: 15px;
  }
  .main-nav {
    position: fixed;
    height: 100%;
    transition: 0.4s ease-in-out;
    left: -100%;
    top: 0;
    background: #fff;
    z-index: 12;
    width: 100%;
    max-width: 280px;
  }
  .main-nav.active {
    left: 0;
  }

  .main-nav-mega-menu {
    position: static;
    visibility: visible;
    opacity: 1;
    transition: none;
    min-width: auto;
    box-shadow: none;
    margin-top: 0 !important;
    padding-bottom: 0;
    background: #eee;
    border-top: 1px solid #d5d0d0;
    display: none;
  }
  .main-nav-mega-menu ul {
    background-color: #eee;
  }
  .megaDropDown > a:after {
    content: "\f107";
    font-family: "Font Awesome 6 Pro";
    border: 0;
    line-height: 1;
    vertical-align: baseline;
    float: right;
    margin-top: 4px;
    color: #aaa;
  }

  .top-strip {
    padding: 13px 0;
    border-bottom: 1px solid #dad7d7;
  }
  .folder-view-documents .item {
    min-width: 20%;
  }
  .support-chat-new.chat-system-wrap .chat-left-asidebar {
    min-width: 390px;
  }

  .chat-system-wrap .chat-left-asidebar {
    min-width: 280px;
  }
  .chat-users-list .chat-user {
    padding-left: 15px;
  }
  .chat-right-page-body,
  .support-chat-new.chat-system-wrap .chat-right-page-body {
    padding: 15px;
  }
  .left-box-email {
    min-width: 100%;
  }
  .right-box-email-main {
    position: fixed;
    background: #fff;
    overflow: auto;
    height: calc(100% - 140px);
    opacity: 0;
    visibility: hidden;
  }
  .right-box-email-main.active {
    opacity: 1;
    visibility: visible;
  }
  .name-subject-from .text-truncate {
    max-width: 100%;
  }
  .inner-email-item {
    padding: 10px 7px;
  }
  .right-box-email {
    position: fixed;
    z-index: 1;
  }
  .new-mail-compose {
    position: fixed;
    background: #fff;
    overflow: auto;
    height: calc(100% - 140px);
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  /* .template-default{
        overflow: auto;
    } */
  .page-wrapper-with-aisde {
    overflow: auto;
  }
  .body-content-top {
    padding: 15px 0;
  }

  .panel-container .panel-left,
  .panel-container .panel-right {
    width: 100%;
    max-width: 100%;
    padding: 0;
    min-width: auto;
  }
  .panel-container .resizer {
    display: none;
  }
  .support-chat-new.chat-system-wrap .chat-left-asidebar {
    min-width: unset;
    max-width: 100%;
  }
  .user-chat-search {
    justify-content: end;
  }

  .user-search-input-div {
    width: 100%;
    overflow: hidden;
    transition: 0.3s;
    margin-right: 10px;
    position: fixed;
    left: 0;
    margin-top: 35px;
    z-index: 3;
    padding: 8px;
    display: none;
  }
  .chat-system-wrap .chat-left-asidebar {
    min-width: 100%;
    overflow: auto !important;
  }
  .chat-right-page-body.overflow-auto {
    overflow: auto !important;
  }

  .chat-system-wrap .chat-right-page {
    position: fixed;
    top: 56px;
    z-index: 13;
    max-height: calc(100% - 56px) !important;
    left: -100%;
    width: 100%;
    transition: 0.3s ease;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
    -ms-transition: 0.3s ease;
    -o-transition: 0.3s ease;
  }
  .chat-system-wrap .chat-right-page.active {
    left: 0;
  }
  .user-search-input-div.active {
    display: block;
  }
  .user-search-input-div .form-control {
    padding: 10px 5px;
  }
  .support-chat-new .chat-right-page-footer {
    min-height: 50px;
  }
  .left-box-email .left-box-email-inner {
    overflow: auto !important;
  }
}

@media (max-width: 576px) {
  html {
    font-size: 58%;
  }

  .top-strip .logo {
    max-width: 24px;
    overflow: hidden;
  }
  .dropdownRight .dropdown-close-btn {
    right: 0;
    left: auto;
  }
  .filter-form-wrap {
    padding: 0 15px;
  }
  .custom-dessign-dropdown .filterBox .title {
    padding-left: 15px;
  }
  .shipment-board-box {
    min-width: fit-content;
  }
  .left-panel-name {
    max-width: 260px;
    min-width: 260px;
  }
  .folder-view-documents .item {
    min-width: 40%;
  }
  .offcanvas.xxl,
  .offcanvas.xl,
  .offcanvas.lg,
  .offcanvas.sm {
    max-width: 100% !important;
  }

  .offcanvas .close-btn {
    position: static;
    width: auto;
    height: auto;
  }
}

@media (min-width: 1200px) {
  .blurDropdownBg.profileDropDownWrap::before,
  .blurNoneXl::before {
    display: none;
  }
  .profileDropDown a i {
    margin-right: 5px;
  }

  @media (-webkit-min-device-pixel-ratio: 1.0416666666666667), (-o-min-device-pixel-ratio: 25/24), (min-resolution: 100dpi) {
    html {
      zoom: 0.9;
      -moz-transform: scale(0.9);
    }
  }

  @media (-webkit-min-device-pixel-ratio: 1.3125), (-o-min-device-pixel-ratio: 21/16), (min-resolution: 126dpi) {
    html {
      zoom: 0.8;
      -moz-transform: scale(0.8);
    }
  }

  @-moz-document url-prefix() {
    body {
      position: static;
      height: 100vh;
    }
    @media (-webkit-min-device-pixel-ratio: 1.0416666666666667), (-o-min-device-pixel-ratio: 25/24), (min-resolution: 100dpi) {
      html {
        zoom: 0.9;
        -moz-transform: scale(1);
      }
    }

    @media (-webkit-min-device-pixel-ratio: 1.3125), (-o-min-device-pixel-ratio: 21/16), (min-resolution: 126dpi) {
      html {
        zoom: 0.8;
        -moz-transform: scale(1);
      }
    }
  }
}
