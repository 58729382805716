body{
    font-family:sans-serif;
    margin:0;
}
.table th{
    font-size:14px;
    text-align:left;
    padding: 0.5rem 0.5rem;
    border: 1px solid #ccc;
    background:#f6f6f6;
}
.table {
    border-collapse:collapse;
}
.table td {
    padding: 0.5rem 0.5rem;
    border: 1px solid #ccc;
}

.table2 {
    border: 1px solid #ccc;
}

.table2 td {
    padding: 0.5rem 0.5rem;
}
 
.dflex01{
    display:flex;
}
.pad-15{
    flex:0 0 30%;
}
.pad01{
    padding-left: 20px;
}
.stend{
    display: inline-block;
    vertical-align: top;
    font-size: 19px;
    font-weight: 700;
    border-right: 1px solid #ccc;
    width: 61px;
    height: 80%;
    max-height: 52px;
}
 
.stend2{
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    width: 150px;
}
 
.fx01{
    flex:1;
}
 
.lh01{
    line-height: 29px;;
}
 
.b2{
    max-width: 1045px;
}
 
.b2 .text-right{
    flex:1;
}
 
.text-right{
    text-align: right;
}

.namedate{
    flex:0 0 35%;
    padding-right:30px;
}

.startend{
    flex:0 0 35%;
    padding-right:30px;
}

.blck{
    background:#111;
    color:#fff;
    font-weight:700;
}